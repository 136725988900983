export const compliments: Array<string> = [
  "Patrzyłem z daleka i myślałem, że to Jan Paweł. Podszedłem i okazało się, że to Ty",
  "Pachniesz jak Kosiarka pokosowa samojezdna na Teresinie",
  "Świetnie się z tobą bawię Słodziaku",
  "Kocham twoje poczucie humoru",
  "Uwielbiam twój śmiech",
  "Fajnego masz dupala",
  "Lubię Twoje dizajny",
  "Zasadziłbym z Tobą Baobaba",
  "Naprawdę dużo wiesz, jesteś bystra i mądra,",
  "Podoba mi się, że jesteś taka pracowita",
  "Świetnie idzie Ci oszczędzanie",
  "Nie mogę się doczekać, aby zobaczyć Cię w sukni ślubnej",
  "Jesteś niesamowicie piękna",
  "Masz wspaniały uśmiech",
  "Twoje oczy są jak błyszczące diamenty",
  "Uwielbiam tulanki z Tobą",
  "Masz niesamowitą figurę",
  "Bardzo szybko pływasz",
  "Twoje poczucie humoru jest tak ostre, że powinno mieć ostrzeżenie dla innych",
  "Miło, że nie pierdzisz w pomieszczeniach",
  "Jesteś jak kawałek ciasta - słodka i apetyczna",
  "Z Tobą zimne ognie są jak fajerwerki",
  "Twoja determinacja i siła są niesamowite",
  "Podziwiam, jak nie poddajesz się w trudnych sytuacjach",
  "Masz niesamowitą empatię",
  "Zawsze potrafisz zrozumieć i wesprzeć swojego Słodziaczka",
  "Twoja inteligencja i charyzma sprawiają, że jesteś wyjątkowa",
  "Czuję się cudownie zawsze, kiedy jestem blisko Ciebie",
  "Dzień staje się piękniejszy, gdy wiem, że mogę zobaczyć Twój uśmiech,",
  "Chciałem Ci tylko przypomnieć o tym, jak niesamowita jesteś i jak wiele znaczysz dla mnie",
  "Miłego dnia, Kochanie",
  "Jesteś jak promyk słońca w moim życiu, który rozświetlasz każdy mój dzień,",
  "Twój dotyk jest jak magiczny eliksir, który sprawia, że czuję się zakochany na nowo,",
  "Robisz świetne makarony",
  "Jesteś wspaniałym kierowniczkiem czerwonej strzały",
  "Jesteś moją największą inspiracją",
  "Twoja miłość sprawia, że świat staje się piękniejszy",
  "Świetnie doprawiasz bigos",
  "Cieszy mnie, że jesteś obok mnie",
  "Nie znam nikogo takiego jak Ty",
  "Jesteś absolutnie wyjątkowa",
  "Jesteś moją ulubioną osobą",
  "Twoja uśmiechnięta twarz to najpiękniejszy obraz, jaki mógłbym oglądać przez cały czas",
  "Jesteś jak cudowne dzieło sztuki, które zawsze zaskakuje i zachwyca nowymi szczegółami",
  "Twoja wyobraźnia jest jak niedościgniony ocean kreatywności, który mnie hipnotyzuje",
  "Uwielbiam Twoją pomysłowość",
  "Jesteś niesamowitym ogarniaczkiem",
  "Twoja dusza promienieje jak milion gwiazd na nocnym niebie",
  "Jesteś piękna zarówno na zewnątrz, jak i wewnątrz",
  "Kiedy patrzę w Twoje oczy, widzę cały wszechświat ukryty w jednym spojrzeniu",
  "Jesteś niezwykła",
  "Twoja energia jest tak cudownie pozytywna, że każdy wokół czuje się lepiej przy Twoim boku",
  "Jesteś tak świetnym rozmówcą, że mogłabym słuchać Cię godzinami",
  "Twoje oczy są tak piękne, że patrzenie w nie to jak zaglądanie w najpiękniejszą tęczę",
  "Twój uśmiech jest jak słońce, które rozświetla moje dni",
  "Nie przestawaj się uśmiechać",
  "Trzymanie cię jest najlepszym lekarstwem na wszystko",
  "Lubię trzymać Cię za rączkę",
  "Masz fajny gust do robienia paznokci",
  "Lubię Twój outfit",
  "Jesteś najbardziej zdumiewającą kobietą, jaką kiedykolwiek spotkałem",
  "Uwielbiam, jak sprawiasz, że zwykłe wydarzenia są warte zapamiętania",
  "Majówka z Tobą to złoto",
  "Kocham z Tobą zjeżdżać na zjeżdżalni basenowy Słodziaczku",
  "Gdyby życie było rzeką, wybrałbym wiosłowanie z tobą",
  "Jesteś jak cień drzewa w upalny letni dzień",
  "Jesteś brakującym elementem układanki, który uzupełnia moje życie",
  "Podoba mi się sposób, w jaki wykorzystujesz swoją inteligencję",
  "Życzliwość, jaką masz dla wszystkich, jest inspirująca",
  "Świetnie czuję się w Twoim towarzystwie",
  "Jestem szczęściarzem, że Cię mam",
  "Moje życie nabiera przy Tobie kolorów",
  "Uwielbiam Twoje poczucie humoru",
  "Czuję się jak na wakacjach, kiedy jesteśmy razem",
  "Życie z Tobą to ciągłe all inclusive",
  "Twoje zdanie jest dla mnie ważne",
  "Rozświetlasz każdy mój dzień",
  "Trudno mi się przy Tobie skupić",
  "Decyzja o pójściu z Tobą na parkową randeczkę była strzałem w dziesiątkę",
  "Decyzja o zaręczynach była najlepszą w życiu",
  "Lubię jak klikasz w Radzieckim Turbo Excelku",
  "Wspaniale organizujesz sobie czas",
  "Super, że prowadzisz kalendarz",
  "Kocham Cię strasznie",
  "Hmm, wyjdziesz za mnie",
  "Jesteś jak albumy Talyor Swift - porządane przez maniaków",
  "Cieszę się na każdą chwilę spędzoną razem",
  "Wydobywasz z życia to, co najlepsze",
  "Jesteś tak słodka, że grozisz cukrzycą",
  "Uwielbiam pasję, którą wkładasz we wszystko",
  "Lubię Cię słuchać, masz zawsze coś ciekawego do powiedzenia",
  "Jesteś rozkoszą dla moich oczu",
  "Przy Tobie odkryłem co to szczęście",
  "Twoja obecność sprawia, że świat staje się lepszy",
  "Jesteś moją najlepszą przyjaciółką, zawsze mogę na Ciebie liczyć",
  "Jesteś inspiracją dla mnie",
  "Dziękuję, że jesteś",
  "Jednak nie wszystkie kobiety są takie same, Ty jesteś wyjątkowa",
  "Od momentu, w którym cię poznałem moje życie stało się prostsze i lepsze",
  "Nauczyłeś mnie różnicy między dziewczyną, a byciem Turbo Słodziakiem",
  "Twoja kreatywność i zdolności artystyczne są oszałamiające",
  "Twój dotyk sprawia, że moje serce bije mocniej",
  "Masz niezwykłą zdolność do zrozumienia mnie ze słowami",
  "Jesteś niesamowicie seksowna",
  "Jesteś bardzo seksowna",
  "Masz doskonałe Ciało",
  "Masz idealną figurę",
  "Masz ciało jak modelka",
  "Uwielbiam Twoje ciało",
  "Masz kapitalny styl",
  "Mało która kobieta ubiera się tak dobrze",
  "Masz niesamowity uśmiech",
  "Widok ciebie w sukienkach jest oszałamiający",
  "Twój głos jest tak hipnotyzujący",
  "Uwielbiam patrzeć jak się śmiejesz",
  "Masz magiczny uśmiech",
  "Doskonale się ubierasz, chciałbym mieć takie wyczucie stylu",
  "Jesteś bardzo fotogeniczna, każde Twoje zdjęcie wygląda świetnie",
  "Montujesz świetne filmiki",
  "Jesteś najlepszą fanką",
  "Lubię z Tobą tańczyć",
  "Świetnie łomoczesz",
  "Jedyne czego żałuję w naszej znajomości to to, że nie poznałem Cię wcześniej",
  "Tak się cieszę, że nasze drogi się skrzyżowały i ze sobą jesteśmy",
  "Masz seksowną osobowość",
  "Marzę o budzeniu się obok ciebie",
  "Przy tobie mam wrażenie, że śnie na jawie",
  "Wyglądasz niesamowicie",
  "Masz słodką fryzurkę",
  "Lubię Twoją grzywkę",
  "Jesteś bardzo fotogeniczna",
  "Naturalne piękno",
  "Jak ktoś może być tak uroczy?",
  "Masz najpiękniejszy uśmiech, jaki kiedykolwiek widziałem",
  "Czy tak wygląda nieoficjalna Miss World?",
  "Pociąga mnie twoje ciepło i inteligencja",
  "Uwielbiam Cię przytulać",
  "Twój głos mnie podnieca",
  "Wspaniale o mnie dbasz",
  "Twoje usta są lepsze od czekolady",
  "Jesteś świetnym słuchaczem i nigdy nie ma z Tobą nudy",
  "Masz coś bardzo magicznego w spojrzeniu",
  "Te oczy to schody do nieba, na które każdy chciałby się wspiąć",
  "Widzę w Twoich oczach wiele ciepła",
  "Nie wiem czy oczy mogą być inteligentne, ale Twoje są",
  "Jeśli oczy są odbiciem duszy, to musisz mieć piękną duszę",
  "Masz dużą wiedzę o chemii",
  "Lubię Twoją karnację",
  "Masz słodziutkie piegi",
  "Masz talent do robienia fajnych fotek",
  "Masz bardzo zgrabną sylwetkę",
  "Zawsze chodzisz wyprostowana",
  "Przy Tobie kwiat paproci to nie tylko legenda",
  "Jesteś jak faroan - KleoPatrynia",
  "Świetnie śpiewasz piosenki Taylor",
  "Ładnie mówisz po angielsku",
  "Wspaniale jeździsz na nartach",
  "Lubię z Tobą saunować",
  "Jesteś bardzo wysportowana",
  "Masz bardzo delikatną skórę",
  "Jesteś bardzo wrażliwa",
  "Umiesz słuchać innych ludzi",
  "Jesteś moją ulubioną osobą",
  "Lubię powroty z Tobą",
  "Masz dobry gust",
  "Masz fajne bimbałki",
  "Masz bardzo długie rzęsy",
  "Masz bardzo długie nogi",
  "Lubię Twoje dłonie",
  "Nosisz stylowe ubranka",
  "Lubię Twoje żarciki",
  "Wspaniale władasz Teresińskim",
  "Jesteś Miluszkiem",
  "Umiesz budować napięcie",
  "Lubię Twoje nagrywki",
  "Świetnie opiekujesz się Bobasami",
  "Byłabyś dobrą mamą",
  "Jesteś niesamowitym kusicielem",
  "Jesteś przekochana",
  "Umiesz w couple goals",
  "Wybierasz dobre jedzonko",
  "Masz głowę do wymyślania atrakcji",
  "Organizujesz wspaniałe randeczki",
  "Wymyślasz ciekawe ksywki",
  "Bardzo się starasz",
  "Jesteś inteligentna",
  "Jesteś Kochaniuszkiem",
  "Bardzo mi z Tobą miło",
  "Kocham Cię",
  "Fajnie, że jesteś",
  "Lubię Twojego dupala",
  "Umiesz mnie zaskoczyć",
  "Umiesz w work-life balance",
  "Jesteś gigaczadem",
  "Jesteś przesłodziutka",
  "Jesteś kochana jak mopsiki",
  "Masz poukładane w głowie",
  "Ba ba ba Babsztyleeeeeek",
  "Nie mogę się zdecydować, który władca jest lepszy - Nabuchodonozor I czy  II?",
  "Lubię z Tobą podróżować",
];
