import React, { useState } from "react";
import "./App.css";
import { compliments } from "./complimentsArray";

function App() {
  const [index, setIndex] = useState(0);
  const randomCompliment = () => {
    setIndex(Math.floor(Math.random() * compliments.length));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Walentynkowe komplemenciki dla Patryni ❤️</h1>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <p className="compliment">{compliments[index]}</p>
        <button className="button-74" onClick={randomCompliment}>
          Losuj
        </button>
      </header>
    </div>
  );
}

export default App;
